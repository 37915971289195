import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Row, Button, Card, Modal, Col } from "react-bootstrap";
import { ConsultantService } from "../../service/ConsultantService";
import Sidebar from "../../layouts/Sidebar";
import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";
import Swal from "sweetalert2";
import Select from "react-select";

export default function ScheduleModal(props) {
  const modalInitialState = props.modalInitialState;
  const [modalState, setModalState] = useState(modalInitialState);
  const handleModalClose = props.handleModalClose;

  const [visitationDays, setVisitationDays] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const daysOfWeekOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];
  const handleDayChange = (selectedOptions) => {
    setVisitationDays(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };
  const handleAddSchedule = async (event) => {
    showLoadingModal();
    event.preventDefault();
    const requestData = {
      visitationDays,
      startTime,
      endTime
    };
    console.log(requestData);
    try {
      const response = await ConsultantService.createSchedule(
        localStorage.getItem("token"),requestData
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Schedule added successfully",
          text: "Schedule has been added successfully",
        });
        handleModalClose();
      }
      
      console.log(response.data);
    } catch (error) {
      console.error('There was an error making the request!', error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while adding schedule",
        });
    }
  };

 

  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={modalState} onHide={handleModalClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add Your Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="card-task">
              <Card.Body className="p-3 pb-1">
              <form id="wizard1">
 
      <section>
        <div className="form-group">
          <label htmlFor="visitationDays">Available Days</label>
          <Select
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            name="visitationDays"
            options={daysOfWeekOptions}
            onChange={handleDayChange}
          />
        </div>
      </section>

      <section>

      <Row>
      <Col className="form-group">
          <label htmlFor="startTime">Start Time:</label>
          <input
            type="time"
            className="form-control"
            id="startTime"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </Col>
        <Col className="form-group">
          <label htmlFor="endTime">End Time:</label>
          <input
            type="time"
            className="form-control"
            id="endTime"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </Col>
      </Row>
      
      </section>
    </form>

              </Card.Body>
              <Card.Footer className="p-3 pt-0"></Card.Footer>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleAddSchedule}>
              Add Schedule
            </Button>

            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
