// useConsultantStats.js
import { useQuery } from 'react-query';
import { ConsultantService } from '../../service/ConsultantService';
import { EnrolleeService } from '../../service/EnrolleeService';

const fetchEnrolleeProfile = async (token,id) => {
  const response = await EnrolleeService.getProfileById(token,id);
  return response.data;
};

 const useEnrolleeProfile= (token,id) => {

  return useQuery(['enrolleeProfile', token], () => fetchEnrolleeProfile(token,id), {
    //Ensure the query runs only if the token and id is available
    enabled: id!=undefined,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: true, // Refetch data when window regains focus
    onError: (error) => {
      console.error('Error fetching enrollee profile:', error);
    },
  });
};

export default useEnrolleeProfile;
