import React from 'react';
import { Form, Button, Nav } from 'react-bootstrap';
import Swal from "sweetalert2";
import {
    showLoadingModal,
    hideLoadingModal,
    convertToBase64,
  } from "../../logic/Utils";
  import { AppointmentService } from "../../service/AppointmentService";
function NoteComposer({ showCompose, setShowCompose, minimize, setMinimize, maximize, setMaximize ,booking}) {

    const [comment, setComment] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
    const handleCommentAction = async () => {
        if (comment.trim() === "") {
          Swal.fire("Error", "Comment cannot be empty", "error");
          return;
        }
        let base64Image = "";
        if (selectedFile) {
          base64Image = await convertToBase64(selectedFile);
    
         
        }
        showLoadingModal();
        const commentDto = {
          comment: comment,
          appointmentId: booking.id,
          imageBase64 : base64Image
        };
    
  
    
        AppointmentService.addComment(localStorage.getItem("token"), commentDto)
          .then((response) => {
            if (response.status === 200) {
              hideLoadingModal();
              Swal.fire("Comment Added", "Your comment has been logged", "success");
              setComment("");
            }
          })
          .catch((error) => {
            hideLoadingModal();
            Swal.fire("Error", error.response.data, "error");
            console.log(error);
          });
      };
    


  return (
<div className={"compose" + (!showCompose ? " d-none" : "") + (maximize ? " fullscreen" : "") + (minimize ? " minimize" : "")}>
      <div className="compose-content">
        <div className="compose-header">
          <h6 className="compose-title">Add Note</h6>
          <Nav as="nav">
            <Nav.Link href="#" className="nav-link-minimize" onClick={() => setMinimize(!minimize)}>
              <i className="ri-subtract-line"></i>
            </Nav.Link>
            <Nav.Link href="#" className="nav-link-fullscreen" onClick={() => setMaximize(!maximize)}>
              <i className="ri-fullscreen-line"></i>
              <i className="ri-fullscreen-exit-line"></i>
            </Nav.Link>
            <Nav.Link href="#" className="nav-link-close" onClick={() => {
              setShowCompose(false);
              setMaximize(false);
              setMinimize(false);
            }}>
              <i className="ri-close-line"></i>
            </Nav.Link>
          </Nav>
        </div>
        <div className="compose-body">
          <Form.Group>
            <Form.Control as="textarea" rows="10" placeholder="Write your note here..."
                onChange={(event) => setComment(event.target.value)}
                value={comment} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload File</Form.Label>
            <Form.Control type="file" 
                onChange={ (event) => handleFileChange(event)}
            />

          </Form.Group>
        </div>
        <div className="compose-footer">
          <Button variant="primary" onClick ={handleCommentAction}type="submit" className="btn-icontext">Save Note</Button>
          <Button variant="" className="btn-white btn-icon"><i className="ri-delete-bin-line"></i></Button>
        </div>
      </div>
    </div>
  );
}

export default NoteComposer;
