import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Card, Col, Nav, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EnrolleeService } from "../service/EnrolleeService";
import prod1 from "../assets/img/prod1.jpg";
import prod2 from "../assets/img/prod2.jpg";
import blog from "../assets/img/blog.png";
import ppp from "../assets/img/ppp.jpg";
import Lottie from "lottie-react";
import animationData from "../assets/animation/animation_lmawejkm.json";
import Swal from "sweetalert2";
import AgoraVideo from "../components/utils/AgoraVideo";
import { AppointmentService } from "../service/AppointmentService";
import ZoomVideoUi from "../components/utils/ZoomVideoUi";
export default function VideoMeeting() {
  const [meetingData, setMeetingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  let profileData = {};
  const profile = localStorage.getItem("profile");
  profileData = JSON.parse(profile);

  useEffect(() => {
    const tokenRegex = /id=([^&]+)/;

    // Get the current URL from the browser
    const currentUrl = window.location.href;

    // Use the regular expression to extract the token from the URL
    const tokenMatch = currentUrl.match(tokenRegex);

    // Check if a token was found and extract it from the match
    const meetingId = tokenMatch ? tokenMatch[1] : null;

    // Do something with the token (e.g., assign it to a variable)

    console.log(meetingId);

 

    if (meetingId == null) {
      Swal.fire("Error", "Id is not Present", "error");
    } else {
      AppointmentService.getMeetingDetails(localStorage.getItem("token"),meetingId)
        .then((response) => {
          if (response.status === 200) {
            setMeetingData(response.data.result);
            setLoading(false);
         
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);



  useEffect(() => {

    let roleOption = localStorage.getItem("role");

    if (roleOption === "ENROLLEE") {
      setRole("ENROLLEE");
     
    }
    else {
      setRole("CONSULTANT");
    }


  }, []);
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Video Consultation
              </li>
            </ol>
          </div>
        </div>

        {loading && (
          <div className={`fullscreen-loader ${loading ? "show" : "hide"}`}>
            <Lottie animationData={animationData} loop={true} autoPlay={true} />
          </div>
        )}
        <div className="App">


 { (meetingData && meetingData.meetingType==="ZOOM_SDK")  && (

  <ZoomVideoUi
  jwt={
    role === "ENROLLEE"?meetingData.zoomJwtTokenForSubscriber:meetingData.zoomJwtTokenForPublisher
   }
  sessionName={meetingData.zoomSessionName}
  userName={
    role === "ENROLLEE"?meetingData.subscriberId:meetingData.publisherId
  }
   />
 )}



    </div>
       
      </div>
    </React.Fragment>
  );
}
