import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import img1 from "../../assets/img/favicon.png";
function Comments(props) {
  const { date, activityDescription, createdBy, imageUrl } = props.log;

  // State to control visibility of the image modal
  const [showImageModal, setShowImageModal] = React.useState(false);

  // Function to toggle image modal
  const toggleImageModal = () => setShowImageModal(!showImageModal);

  return (
    <React.Fragment>
      <li className="activity-item comment">
        <p className="d-sm-flex align-items-center mb-2">
          <Link to="" className="avatar avatar-xs me-2 d-none d-sm-inline">
            <img src={img1} alt="" />
          </Link>
          <span className="fs-sm">
            <strong>{createdBy}</strong>{" "}
          </span>
        
          <span className="text-secondary fs-xs ms-auto">{date}</span>
          {imageUrl && (
              <Button
                variant="primary"
                href={imageUrl}
                target="_blank"  // Opens in a new tab
                rel="noopener noreferrer" 
                style={{ marginLeft: '10px' }}
              >
                Details
              </Button>
            )}
        </p>
        <Card className="card-comment">
          <Card.Body>
            {activityDescription}
          
          </Card.Body>
        </Card>
      </li>
    </React.Fragment>
  );
}

export default Comments;