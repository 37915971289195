import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BookingCard from '../../components/BookingCard';

const EnrolleeProfile= ({ mediCloudEnrolleeResponse, appointments }) => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Card>
            <Card.Body>
             
              <Row>
                <Col sm={6}>
                  <p><strong>Full Name:</strong> {mediCloudEnrolleeResponse.fullName}</p>
                  <p><strong>Enrollee ID:</strong> {mediCloudEnrolleeResponse.enrolleeId}</p>
                  <p><strong>Plan Type:</strong> {mediCloudEnrolleeResponse.planType}</p>
                  <p><strong>Address:</strong> {mediCloudEnrolleeResponse.address}</p>
                  <p><strong>Group Name:</strong> {mediCloudEnrolleeResponse.groupName}</p>
                  <p><strong>Member Status:</strong> {mediCloudEnrolleeResponse.memberStatus}</p>
                  <p><strong>Gender:</strong> {mediCloudEnrolleeResponse.gender}</p>
                 
                </Col>
                <Col sm={6}>
                  <p><strong>Email:</strong> {mediCloudEnrolleeResponse.email1}</p>
                  <p><strong>Phone:</strong> {mediCloudEnrolleeResponse.phone1}</p>
                  <p><strong>Country:</strong> {mediCloudEnrolleeResponse.country}</p>
                  <p><strong>Effective Date:</strong> {mediCloudEnrolleeResponse.effectiveDate}</p>
                  <p><strong>Termination Date:</strong> {mediCloudEnrolleeResponse.terminationDate || 'N/A'}</p>
                  <p><strong>Principal ID:</strong> {mediCloudEnrolleeResponse.principalID || 'N/A'}</p>
                  <p><strong>Dependent Number:</strong> {mediCloudEnrolleeResponse.dependentNumber}</p>
                  <p><strong>Date of Birth:</strong> {mediCloudEnrolleeResponse.dateOfBirth}</p>

                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Appointments</h2>
              {appointments.map((booking, index) => (
                <BookingCard key={index} booking={booking}  />
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EnrolleeProfile;
