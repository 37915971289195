// useConsultantStats.js
import { useQuery } from 'react-query';
import { ConsultantService } from '../../service/ConsultantService';

const fetchConsultantStats = async (token) => {
  const response = await ConsultantService.getStats(token);
  return response.data;
};

const fetchSchedule = async (token) => {
    const response = await ConsultantService.getSchedulesForConsultant(token);
    return response.data;
  };

 const useConsultantStats = (token) => {
  return useQuery(['consultantStats', token], () => fetchConsultantStats(token), {
    enabled: !!token, // Ensure the query runs only if the token is available
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: true, // Refetch data when window regains focus
    onError: (error) => {
      console.error('Error fetching consultant stats:', error);
    },
  });
};

const useFetchSchedule = (token) => {
    
    return useQuery(['fetchSchedule', token], () => fetchSchedule(token), {
      enabled: !!token, // Ensure the query runs only if the token is available
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
      refetchOnWindowFocus: true, // Refetch data when window regains focus
      onError: (error) => {
        console.error('Error fetching consultant schedule:', error);
      },
    });
  };

export { useConsultantStats,useFetchSchedule };
