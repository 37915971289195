import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import { Button, Card, Modal, Offcanvas, } from "react-bootstrap";
import { AppointmentService } from "../../service/AppointmentService";
import Sidebar from "../../layouts/Sidebar";
import {
  showLoadingModal,
  hideLoadingModal,
  
} from "../../logic/Utils";
import Swal from "sweetalert2";
import img1 from "../../assets/img/favicon.png";
import * as PropTypes from "prop-types";
import EnrolleeProfile from "./EnrolleeProfile";
import useEnrolleeProfile from "../../logic/hooks/EnrolleeHooks";
import BookingCard from "../../components/BookingCard";
import CancelReasonModal from "../../components/CancelReasonModal";
import CreateMeetingModal from "./CreateMeetingModal";
import NoteComposer from "./NoteComposer";
import Comments from "./Comments";




export default function BookingModalView(props) {
  const modalBooking = props.booking;
  const refreshAction = props.refreshAction;
  const [booking, setBooking] = useState(modalBooking);
  const modalInitialState = props.modalInitialState;
  const [modalState, setModalState] = useState(modalInitialState);
  const [enrolleeProfile, setEnrolleeProfile] = useState({});
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [creatingMeetingModalState, setCreatingMeetingModalState] =
    useState(false);
  const currentTime = moment();
  const meetingStartTime = moment(booking?.rawStartDateTime);
  const meetingEndTime = moment(booking?.rawEndDateTime);
  const timeDifference = meetingStartTime.diff(currentTime, "minutes");
  const [taskLog, setTaskLog] = React.useState([]);

  const [showCompose, setShowCompose] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [maximize, setMaximize] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleCompose = () => {
    setShowCompose(prevState => !prevState);
  };
  

  const token = localStorage.getItem("token");
  const { data, error, isLoading, refetch } = useEnrolleeProfile(
    localStorage.getItem("token"),
    booking?.enrolleeNumber
  );

  const handleModalClose = modalBooking
    ? props.handleModalClose
    : () => {
        setModalState(false);
      };
  const [role, setRole] = useState("");
  const navigate = useNavigate();


  const handleMeetingStart = (booking) => {
   



    if (timeDifference > 15 && booking.meetingType !== "GOOGLE_MEET") {
     
      Swal.fire({
        icon: "warning",
        title: "Too Early",
        text: "You cannot start the meeting more than 15 minutes before the scheduled time.",
      });
      return;
    }

    if (
      currentTime.isAfter(meetingEndTime) &&
      booking.meetingType !== "GOOGLE_MEET"
    ) {
      Swal.fire({
        icon: "error",
        title: "Meeting Ended",
        text: "You cannot start the meeting after it has ended.",
      });
      return;
    }

    if (role === "ENROLLEE") {
      window.open(booking.meetingLink, "_blank");
    } else if (role === "CONSULTANT") {
      window.open(booking.startLink, "_blank");
    }
  
  };

  const handleMeetingCompletion = (booking) => {
  

    Swal.fire({
      title: 'Complete this Consultation',
      text: "Do you want to add a note before completing this consultation?",
      icon: 'error',
      showCancelButton: true, // Show the "Cancel" button
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes', // Text for the "Confirm" button
      cancelButtonText: 'No' // Text for the "Cancel" button
    }).then((result) => {
      if (result.isConfirmed) {
    handleToggleCompose();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
        handleCompleteApiCall(booking);
      }
    });

  
  };

  // const handleCancel = (booking) => {
  const handleCancel = (booking) => {
    setShowCancelModal(true);
  };

  const handleEnrolleeProfile = async () => {
    showLoadingModal();

    try {
      // Check if there's an error after refetching
      if (error) {
        throw new Error(error.message);
      }

      // If data is successfully fetched
      if (data) {
        setEnrolleeProfile(data.result);
        setShowOffCanvas(true);
        setModalState(false);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err.message,
      });
    } finally {
      hideLoadingModal();
    }
  };

  const handleCreatingMeetingModal = (booking) => {
    setCreatingMeetingModalState(true);
    setModalState(false);
  };
  const handleCanvasClose = () => {
    setShowOffCanvas(false);
    setModalState(true);
  };

  const handleCreatingMeetingModalClose = () => {
    setCreatingMeetingModalState(false);
    setModalState(true);
  };
  const handleApproval = (booking) => {
    showLoadingModal();
    AppointmentService.approveAppointment(
      localStorage.getItem("token"),
      booking.id
    )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Appointment Approved Successfully",
          }).then((result) => {
            if (result.isConfirmed) {
              handleModalClose();
              refreshAction();
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      });
  };

  const handleCompleteApiCall = (booking) => {
    showLoadingModal();
    AppointmentService.completeAppointment(
      localStorage.getItem("token"),
      booking.id
    )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Appointment Completed Successfully",
          }).then((result) => {
            if (result.isConfirmed) {
              handleModalClose();
              refreshAction();
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    let roleOption = localStorage.getItem("role");

    if (roleOption === "ENROLLEE") {
      setRole("ENROLLEE");
    } else {
      setRole("CONSULTANT");
    }
  }, []);

  useEffect(() => {
    if (booking) {
      setLoading(true);
      AppointmentService.getALogOfAnAppointment(
        localStorage.getItem("token"),
        booking.id
      )
        .then((response) => {
          setTaskLog(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching logs:", error);
        });
    }
  }, [booking]);

  useEffect(() => {
    if (modalBooking !== null && modalBooking !== undefined) {
      // console.log("modalBooking", modalBooking);
      setBooking(modalBooking);
      return;
    }

    const getAppointmentIdFromUrl = () => {
      const tokenRegex = /id=([^&]+)/;
      const currentUrl = window.location.href;
      const tokenMatch = currentUrl.match(tokenRegex);
      return tokenMatch ? tokenMatch[1] : null;
    };

    const appointmentId = getAppointmentIdFromUrl();

    console.log("appointmentId", appointmentId);

    if (!appointmentId) {
      Swal.fire("Error", "Id is not Present", "error");
      return;
    }
    const fetchBookingDetails = async () => {
      try {
        showLoadingModal();
        const response = await AppointmentService.getABooking(
          localStorage.getItem("token"),
          appointmentId
        );

        if (response.status === 200) {
          setBooking(response.data.result);
          setModalState(true);
          hideLoadingModal();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/bookings", { replace: true });
          }
        });
        console.error("Error fetching booking details:", error);
      }
    };

    fetchBookingDetails();
  }, [modalBooking]);

  return (
    <React.Fragment>
      <Header />
      <Sidebar />

      {booking && (
        <div className="main main-docs">
          <Modal show={modalState} onHide={handleModalClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Appointment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
      <BookingCard booking={booking} />
      <div className="chat-body-footer">
     
      </div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="section-title mb-0 mt-0">Notes</h5>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading Notes...</span>
          </div>
        </div>
      ) : (
        <>
          {taskLog.length === 0 ? (
            <div>
              <p>No appointment notes</p>
            </div>
          ) : (
            <div>
              <ul className="activity-group mb-5">
                {taskLog.map((log, index) => (
                  <Comments key={index} log={log} />
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      
      <NoteComposer
    minimize={minimize}
    setMinimize={setMinimize}
    maximize={maximize}
    showCompose={showCompose}
    setShowCompose={setShowCompose}
    booking={booking}
  />

    </Modal.Body>
  
            <Modal.Footer>
              <div className="d-flex justify-content-between w-100">
              <Button variant="primary" onClick={handleToggleCompose}>
        {showCompose ? 'Close Note Composer' : 'Add Note'}
      </Button>
                {booking.appointmentStatus === "APPROVED" &&
                  !currentTime.isAfter(meetingEndTime) && (
                    <Button
                      variant="primary"
                      onClick={() => handleMeetingStart(booking)}
                    >
                      Start Consultation
                    </Button>
                  )}

                {role === "CONSULTANT" && (
                  <>
                    {booking.appointmentStatus === "NEW" && (
                      <Button
                        variant="success"
                        onClick={() => {
                          handleApproval(booking);
                        }}
                      >
                        Approve Consultation
                      </Button>
                    )}
                    {booking.appointmentStatus === "APPROVED" &&
                  (
                    <Button
                      variant="primary"
                      onClick={() => handleMeetingCompletion(booking)}
                    >
                      Complete Consultation
                    </Button>
                  )}
                    {booking.appointmentStatus === "NEW" && (
                      <Button
                        variant="warning"
                        onClick={() => {
                          handleCreatingMeetingModal(booking);
                        }}
                      >
                        Reschedule Consultation
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleEnrolleeProfile(booking);
                      }}
                    >
                      View Enrollee Profile
                    </Button>
                  </>
                )}

                {role === "ENROLLEE" && (
                  <>
                    {(booking.appointmentStatus === "NEW" ||
                      booking.appointmentStatus === "APPROVED") && (
                      <Button
                        variant="danger"
                        onClick={() => handleCancel(booking)}
                      >
                        Cancel Appointment
                      </Button>
                    )}
                  </>
                )}

                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
          {showCancelModal && (
            <CancelReasonModal
              show={showCancelModal}
              handleClose={() => setShowCancelModal(false)}
              handleParentClose={() => setModalState(false)}
              refreshAction={refreshAction}
              showLoadingModal={showLoadingModal}
              hideLoadingModal={hideLoadingModal}
              booking={booking}
            />
          )}
       
          {creatingMeetingModalState && (
            <CreateMeetingModal
              modalInitialState={creatingMeetingModalState}
              handleModalClose={handleCreatingMeetingModalClose}
              booking={booking}
            />
          )}



          {showOffCanvas && (
            <div className="main main-docs">
              <Offcanvas
                show={showOffCanvas}
                onHide={handleCanvasClose}
                backdrop={true}
                placement="end"
                style={{ width: "40vw" }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Enrollee Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <EnrolleeProfile
                    mediCloudEnrolleeResponse={
                      enrolleeProfile.mediCloudEnrolleeResponse
                    }
                    appointments={enrolleeProfile.appointments}
                  />
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
