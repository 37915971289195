import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import {
    Spinner,
  } from "react-bootstrap";


  const showLoadingModal = (text) => {

  
    if (text === undefined) {
      text = "Processing";
    }
  
    // Open Swal modal with the Bootstrap Spinner component and animation styles
    Swal.fire({
      title: ` ${text}`,
      allowOutsideClick: true,
      showConfirmButton: false,
    });
  };

  // Function to hide the loading modal
  const hideLoadingModal = () => {
    // Close the Swal modal
    Swal.close();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  export {showLoadingModal,hideLoadingModal,convertToBase64};