import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // React Router for URL param and navigation
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2"; // SweetAlert2 for the alert

export default function Authorization() {
  const [verificationToken, setVerificationToken] = useState("");
  const navigate = useNavigate(); // Hook for navigation
  const [isAuthorizing, setIsAuthorizing] = useState(true);

  useEffect(() => {
    // Define a regular expression to match the 'token' parameter in the URL
    const tokenRegex = /token=([^&]+)/;

    // Get the current URL from the browser
    const currentUrl = window.location.href;

    // Use the regular expression to extract the token from the URL
    const tokenMatch = currentUrl.match(tokenRegex);

    // Check if a token was found and extract it from the match
    const token = tokenMatch ? tokenMatch[1] : null;

    // Set the token in state if it's found
    if (token) {
      setVerificationToken(token);
    }
  }, []);

  // Perform token validation and role-based redirection
  const handleVerification = () => {
    if (verificationToken) {
      try {
        const decodedToken = jwt_decode(verificationToken);

        // Check if the token is expired
        const currentTime = Date.now();
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

        if (currentTime > expirationTime) {
          // Token is expired
          Swal.fire({
            title: "Session Expired",
            text: "Your token has expired. Please log in again.",
            icon: "warning",
            confirmButtonText: "OK",
          });
          return;
        }

        // Save token and user details in localStorage
        localStorage.setItem("token", verificationToken);
        localStorage.setItem("profile", decodedToken.profile);
        localStorage.setItem("role", decodedToken.roles[0]);
        localStorage.setItem("expirationTime", expirationTime.toString());

        // User-friendly alert (SweetAlert)
        Swal.fire({
          title: "Authorizing...",
          text: "We are logging you in, please wait.",
          icon: "info",
          timer: 2000,
          showConfirmButton: false,
        });

        // Redirect based on the user role after a slight delay for the alert to be shown
        let destination = "/dashboard/enrollee/medicloud";
        if (decodedToken.roles.includes("ADMIN")) {
          destination = "/dashboard/admin";
        }

        setTimeout(() => {
          navigate(destination); // Redirect after the alert
        }, 2000);
      } catch (error) {
        // Handle invalid token
        console.error("Token decoding failed:", error);
        Swal.fire({
          title: "Error",
          text: "Invalid or malformed token. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      console.log("No token provided");
      Swal.fire({
        title: "Error",
        text: "No authorization token provided!",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    handleVerification();
  }, [verificationToken]);

  return <div>{/* Display nothing specific as the SweetAlert will show the authorization process */}</div>;
}
