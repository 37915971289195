import React from 'react';
import { Card, Col } from 'react-bootstrap';
import PlaceholderComponent from './PlaceholderComponent';

const CustomCard = ({ stats, handleClick, iconClass, value, label }) => (
  <Col xs="6" md="3" xl="6">
    {stats ? (
      <Card onClick={handleClick} className="card-one card-product">
        <Card.Body className="p-3">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div className="card-icon">
              <i className={iconClass}></i>
            </div>
          </div>
          <h2 className="card-value ls--1">{value}</h2>
          <label className="card-label fw-medium text-dark">{label}</label>
        </Card.Body>
      </Card>
    ) : (
      <PlaceholderComponent type="card" />
    )}
  </Col>
);

export default CustomCard;
