import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Joyride from 'react-joyride';
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import blog from "../assets/img/blog.png";
import ppp from "../assets/img/ppp.jpg";

import { AppointmentService } from "../service/AppointmentService";
import BookingModalView from "../pages/bookings/BookingModalView";
import BookingTable from "../components/BookingTable";
import PlaceholderComponent from "../components/PlaceholderComponent";
import WhatsappSupport from "../components/WhatsappSupport";

export default function EnrolleeDashboard() {
  const [greeting, setGreeting] = useState("");
  const userDetailsJSON = localStorage.getItem("profile");
  const profileData = JSON.parse(userDetailsJSON);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookingsList] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showBookings, setShowBookings] = useState(false);

  const handleBookingView = (booking) => {
    setSelectedBooking(booking);
    setModalState(true);
  };

  const handleClose = () => {
    // Implement your edit logic here
    setModalState(false);
    setSelectedBooking(null);
  };

  const makePhoneCall = () => {
    const number = "08123456789";
    const link = document.createElement('a');
    link.href = `tel:${number}`;
    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  useEffect(() => {
    AppointmentService.getMyBookings(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          setBookingsList(response.data.result);
          setShowBookings(true);
          // setTimeout(() => {
          setLoading(false); // Set loading to false when your data is fetched
          // }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const refreshBookings = () => {
    setShowBookings(false);
    AppointmentService.getMyBookings(localStorage.getItem("token"))
    .then((response) => {
      if (response.status === 200) {
        setBookingsList(response.data.result);
        setShowBookings(true);
        // setTimeout(() => {
        setLoading(false); // Set loading to false when your data is fetched
        // }, 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }


  // Emoji component to display emoji
  const Emoji = ({ symbol, label }) => (
    <span role="img" aria-label={label}>
      {symbol}
    </span>
  );

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour < 12) {
      setGreeting(`Good Morning`);
    } else if (currentHour < 18) {
      setGreeting(`Good Afternoon`);
    } else {
      setGreeting(`Good Evening`);
    }
  }, []);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const [steps] = useState([

   

    {
      target: '#bookingsTable',
      content: 'These are your consultations.',
    },
    {
      target: '#slots',
      content: 'You can book a video consultation here by clicking on the button.',
    },
    {
      target: '#tollfree',
      content: 'Talk to a Doctor (Toll-Free)',
    }



  ]);
  

  return (
    <React.Fragment>
     <Joyride
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      />
 
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Enrollee
              </li>
            </ol>
            <br />
            <h4 className="main-title mb-0">
              {greeting} {profileData ? profileData.firstName : ""}{" "}
              <Emoji symbol="👋" label="smiley" />
            </h4>
            <br />
            <h6>Welcome to your Dashboard</h6>
          </div>
        </div>

      

        {!loading && (
          <Row className="g-3">
            <Col xl="5">
              <Row className="g-3">
                <Col xs="6" md="3" xl="6">
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="card-icon">
                          <i className="ri-heart-add-fill"></i>
                        </div>
                      </div>
                      <h2 className="card-value ls--1">
                        {profileData.planName}
                      </h2>
                      <label className="card-label fw-medium text-dark">
                        Health Plan Coverage
                      </label>
                      <span className="d-flex gap-1 fs-xs">
                        <span
                          className={
                            profileData.isterminated == "true"
                              ? "d-flex align-items-center text-danger"
                              : "d-flex align-items-center text-success"
                          }
                        >
                          <span className="ff-numerals">
                            {profileData.isterminated == "true"
                              ? "STATUS: INACTIVE"
                              : "STATUS: ACTIVE"}
                          </span>
                          <i
                            className={
                              profileData.isterminated == "true"
                                ? "ri-arrow-down-line"
                                : "ri-arrow-up-line"
                            }
                          ></i>
                        </span>
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="6" md="3" xl="6">
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="card-icon">
                          <i className="ri-empathize-fill"></i>
                        </div>
                      </div>
                      <h2 className="card-value ls--1">
                        {profileData.enrolleeId != null
                          ? profileData.enrolleeId
                          : "Not Set"}
                      </h2>
                      <label className="card-label fw-medium text-dark">
                        Enrollee Number
                      </label>
                      <span className="d-flex gap-1 fs-xs"></span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="6" md="3" xl="6">
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="card-icon">
                          <i className="ri-health-book-fill"></i>
                        </div>
                      </div>
                      <h2 className="card-value ls--1">Coverage Details</h2>
                      {/* <label className="card-label fw-medium text-dark">Coverage Stattistica</label> */}
                      <span className="d-flex gap-1 fs-xs">
                        <span
                          className={"d-flex align-items-center text-success"}
                        >
                          <Row>
                            <span className="ff-numerals">
                              Starting Date: <br></br>
                              {profileData.effectiveDate != null
                                ? profileData.effectiveDate
                                : "Not Set"}{" "}
                            </span>
                          </Row>
                        </span>

                        <span
                          className={"d-flex align-items-center text-danger"}
                        >
                         
                        </span>
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="6" md="3" xl="6">
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="card-icon">
                          <i className="ri-first-aid-kit-fill"></i>
                        </div>
                      </div>
                      <h2 className="card-value ls--1">
                        {" "}
                        Provider Information
                      </h2>
                      <label className="card-label fw-medium text-dark">
                        {profileData.providerName != null
                          ? profileData.providerName
                          : "Not Set"}
                      </label>
                    
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xl="7">
              <Row className="g-2">
                <Card id ="bookingsTable" className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Latest Booked Consultations</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="p-3">
                  {showBookings ?(
                    <BookingTable
                        id={"bookings"}
                        bookings={bookings}
                        role={"ENROLLEE"}
                        handleBookingView={handleBookingView}
                      />
                  ): ( <PlaceholderComponent type="table" />)}

                    {(bookings.length === 0 && showBookings) && <h6>No Upcoming Appointment</h6>}
                  </Card.Body>
                </Card>

                <Row className="g-3 ">
                    <Col id ="slots"  xs="6" md="3" xl="6" >
                    <Link to={"/slots"}>
                        <div className={"task-category p-3 bg-success"}>
                          <h5 className="category-title mb-1">
                            {"Book a Video Consultation"}
                          </h5>
                        </div>
                      </Link>
                      </Col>
                      <Col id="tollfree" xs="6" md="3" xl="6" >
                      <Link onClick={makePhoneCall}>
                        <div className={"task-category p-3 bg-primary"}>
                          <h5 className="category-title  mb-1">
                          Talk to a Doctor (Toll-Free)
                          </h5>
                        </div>
                      </Link>
                    </Col>

                </Row>
              </Row>
            </Col>

            <Col xl="8"></Col>
            <Col xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    Explore Our Recommended Health Plan
                  </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="#">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="#">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <Row className="g-3">
                    <Col sm="5" md="4" xl="5" xxl="4">
                      <div className="rounded px-2 bg-white">
                        <img
                          src={ppp}
                          className="img-fluid"
                          alt="Health Plan"
                        />
                      </div>
                    </Col>
                    <Col
                      sm="7"
                      md="8"
                      xl="7"
                      xxl
                      className="d-flex flex-column"
                    >
                      <h6 className="fs-15 fw-semibold text-dark mb-1">
                        METRO PPP
                      </h6>
                      <p className="text-secondary mb-3">
                        Our Ultimate Health Plan
                      </p>
                      <ul className="list-unstyled">
                        <li>- Tailored for a healthy lifestyle</li>
                        <li>- Extensive coverage options</li>
                        <li>- Access to top-tier medical professionals</li>
                        <li>- Holistic wellness support</li>
                      </ul>
                      <div className="d-flex align-items-center justify-content-between mt-auto">
                        <Link to="">
                          <h6 className="card-value fw-semibold fs-15 mb-0">
                            Learn More
                          </h6>
                        </Link>

                        <div className="d-flex gap-1 text-primary fs-14">
                          <i className="ri-star-fill"></i>
                          <i className="ri-star-fill"></i>
                          <i className="ri-star-fill"></i>
                          <i className="ri-star-fill"></i>
                          <i className="ri-star-fill"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Latest Blog Post</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <Row className="g-3">
                    <Col sm="5" md="4" xl="5" xxl="4">
                      {/* <div className="rounded px-2 bg-white"> */}
                      <img src={blog} className="img-fluid" alt="" />
                      {/* </div> */}
                    </Col>
                    <Col sm="8" md="9" xl="8" className="d-flex flex-column">
                      <br />
                      <h6 className="fw-semibold text-dark lh-5">
                        13 Proven tips for Loosing weight.- Guaranteed to
                        produce results
                      </h6>
                      <p className="text-secondary">
                        Losing weight can be a challenging and frustrating
                        journey, but with the right tools and strategies, it can
                        also be a fulfilling and empowering one.
                      </p>
                      <div className="product-review-footer mt-auto">
                        <Link to="https://www.metrohealthhmo.com/13-proven-tips-for-loosing-weight-guaranteed-to-produce-results/">
                          Read More
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {modalState && selectedBooking && (
          <BookingModalView
            booking={selectedBooking}
            modalInitialState={modalState}
            handleModalClose={handleClose}
            refreshAction={refreshBookings}
          />
        )}
      </div>
      <WhatsappSupport />
    </React.Fragment>
  );
}
