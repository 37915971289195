import axios from "axios";
import jwtDecode from "jwt-decode";

const CREATE = process.env.REACT_APP_BACKEND_URL + "appointment/create";
const CREATEBYCONSULTANT = process.env.REACT_APP_BACKEND_URL + "appointment/create/consultant";
const GET_MY_APPOINTMENTS_ENROLEEE = process.env.REACT_APP_BACKEND_URL + "appointment/enrollee";
const GET_MEETINGS_DETAILS = process.env.REACT_APP_BACKEND_URL + "appointment/details?meetingId=";
const GET_MY_APPOINTMENTS_CONSULTANT = process.env.REACT_APP_BACKEND_URL + "appointment/all/consultant";
const APPROVE_APPOINTMENTS = process.env.REACT_APP_BACKEND_URL + "appointment/approve/";
const CANCEL_APPOINTMENTS = process.env.REACT_APP_BACKEND_URL + "appointment/cancel/";
const COMPLETE_APPOINTMENTS = process.env.REACT_APP_BACKEND_URL + "appointment/complete/";
const RESCHEDULE = process.env.REACT_APP_BACKEND_URL + "appointment/reschedule";
const GET_AN_APPOINTMENT = process.env.REACT_APP_BACKEND_URL + "appointment/";
const GET_LOG = process.env.REACT_APP_BACKEND_URL + "appointment/all/comments/";
const ADD_COMMENT = process.env.REACT_APP_BACKEND_URL + "appointment/comment/add";
class appointmentService {
  // Check if the token is expired
  isTokenExpired(token) {
    if (token === null) {
      return true; 
  }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;


  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

  getMyBookings(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_MY_APPOINTMENTS_ENROLEEE+"?limit=5", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getAllMyBookings(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_MY_APPOINTMENTS_ENROLEEE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getMyBookingsForConsultant(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_MY_APPOINTMENTS_CONSULTANT+"?limit=5", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getAllMyBookingsForConsultant(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_MY_APPOINTMENTS_CONSULTANT, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  getABooking(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_AN_APPOINTMENT + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getALogOfAnAppointment(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_LOG + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getMeetingDetails(token,meetingId) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_MEETINGS_DETAILS + meetingId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  createAppointment(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(CREATE, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  createAppointmentByConsultant(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(CREATEBYCONSULTANT, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  rescheduleAppointment(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(RESCHEDULE, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  addComment(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(ADD_COMMENT, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }


  approveAppointment(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(APPROVE_APPOINTMENTS + id, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  cancelAppointment(token, id, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(CANCEL_APPOINTMENTS + id, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  completeAppointment(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(COMPLETE_APPOINTMENTS + id, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
 
}

export const AppointmentService = new appointmentService();
