import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { Card, Col, Nav, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import favicon from "../assets/img/favicon.png";
import Avatar from "../components/Avatar";
import { AppointmentService } from "../service/AppointmentService";
import BookingModalView from "../pages/bookings/BookingModalView";
import ScheduleModal from "../pages/bookings/ScheduleModal";
import Swal from "sweetalert2";
import { ConsultantService } from "../service/ConsultantService";
import PlaceholderComponent from "../components/PlaceholderComponent";
import CustomCard from "../components/CustomCard";
import { hideLoadingModal, showLoadingModal } from "../logic/Utils";
import { useConsultantStats, useFetchSchedule } from "../logic/hooks/ConsultantHooks";
import BookingTable from "../components/BookingTable";
import CreateMeetingModal from "../pages/bookings/CreateMeetingModal";

export default function ConsultantDashboard() {
  const [greeting, setGreeting] = useState("");
  const userDetailsJSON = localStorage.getItem("profile");
  const profileData = JSON.parse(userDetailsJSON);
  const [bookings, setBookingsList] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [scheduleModalState, setScheduleModalState] = useState(false);
  const [creatingMeetingModalState, setCreatingMeetingModalState] = useState(false);
  const [stats, setStats] = useState(null);
  const [showBookings, setShowBookings] = useState(false);
  const token = localStorage.getItem("token");
  const {
    data: consultantStats,
    error: consultantStatsError,
    isLoading: isConsultantStatsLoading,
    refetch: refetchConsultantStats
  } = useConsultantStats(token);
  const {
    data: scheduleData,
    error: scheduleError,
    isLoading: isScheduleLoading,
    refetch: refetchSchedule
  } = useFetchSchedule(token);

 const navigate = useNavigate();


  const handleBookingPage = () => {
    navigate("/bookings"); // Replace with your desired path
  };
  const handleBookingView = (booking) => {
    setSelectedBooking(booking);
    setModalState(true);
  };

  const handleAddSchedule = () => {
    setScheduleModalState(true);
  };

  const handleCreateMeeting = () => {
    setCreatingMeetingModalState(true);
  };

  const handleClose = () => {
    // Implement your edit logic here
    setModalState(false);
    setSelectedBooking(null);
    setScheduleModalState(false);
    setCreatingMeetingModalState(false);
  };

  useEffect(() => {
  
    if (scheduleData) {
      setSchedules(scheduleData.result);
    }
  }, [scheduleData]);

  useEffect(() => {
    if (consultantStats) {
      setStats(consultantStats.result);
    }
  }, [consultantStats]);
 

useEffect(() => {
  AppointmentService.getMyBookingsForConsultant(localStorage.getItem("token"))
  .then((response) => {

    if (response.status === 200) {
      setBookingsList(response.data.result);
      setShowBookings(true);
    }
  })
  .catch((error) => {
    console.log(error);
  });
}, []);
const refreshBookings = () => {
  setShowBookings(false);
  AppointmentService.getMyBookingsForConsultant(localStorage.getItem("token"))
  .then((response) => {

    if (response.status === 200) {
      setBookingsList(response.data.result);
      setShowBookings(true);
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

  const handleCancel = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this schedule",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelSchedule(item);
      }
    });
  };

  const cancelSchedule = (item) => {

    showLoadingModal();
    ConsultantService.cancelSchedule(token, item.id)
    .then((response) => {
      if (response.status === 200) {
        setSchedules([]);
        refetchSchedule();
        // setSchedules(schedules.filter((schedule) => schedule.id !== item.id));
        Swal.fire({
          title: "Success",
          text: "Schedule Cancelled Successfully",
          icon: "success",
        });
      }
    })
    .catch((error) => {
      console.log(error);
      hideLoadingModal();
    });}
  // Emoji component to display emoji
  const Emoji = ({ symbol, label }) => (
    <span role="img" aria-label={label}>
      {symbol}
    </span>
  );

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour < 12) {
      setGreeting(`Good Morning`);
    } else if (currentHour < 18) {
      setGreeting(`Good Afternoon`);
    } else {
      setGreeting(`Good Evening`);
    }
  }, []);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Consultant
              </li>
            </ol>
            <br />
            <h4 className="main-title mb-0">
              {greeting} {profileData ? profileData.firstName : ""}{" "}
              <Emoji symbol="👋" label="smiley" />
            </h4>
            <br />
            <h6>Welcome to your Dashboard</h6>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="5">
            <Row className="g-3">
              <CustomCard
                stats={stats}
                handleClick={handleBookingPage}
                iconClass="ri-heart-add-fill"
                value={`${stats ? stats.pendingApprovalConsultations : 0} Bookings`}
                label="Pending Approval"
              />
              <CustomCard
                stats={stats}
                handleClick={handleBookingPage}
                iconClass="ri-empathize-fill"
                value={`${stats ? stats.completedConsultations : 0} Completed`}
                label="Video Consultations"
              />

              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">My Schedules</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  {!isScheduleLoading  ? (
                    <Col xs="12">
                      <Table className="table-agent mb-0" responsive>
                        <tbody>
                          {schedules.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  {/* <Avatar img={prod1} /> */}
                                  <div>
                                    <h6 className="mb-0">{item.dayOfWeek}</h6>
                                    {/* <span className="fs-xs text-secondary">{item.startTime}</span> */}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <span className="badge bg-success">
                                  {item.title}
                                </span>
                              </td>

                              <td>
                                <div className="d-flex justify-content-end">
                                  <Link
                                    to=""
                                    className="link-more"
                                    onClick={() => handleCancel(item)}
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  ): ( <PlaceholderComponent type="table" />)}
                  {(schedules.length === 0 && !isScheduleLoading) && <h6>No Schedules Registered</h6>}
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col xl="7">
            <Row className="g-2">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Booked Consultations</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  {showBookings ?(
                    <BookingTable bookings={bookings} handleBookingView={handleBookingView} />
     
                  ): ( <PlaceholderComponent type="table" />)}

                  {(bookings.length === 0 && showBookings) && <h6>No Upcoming Appointment</h6>}
                </Card.Body>
              </Card>

              <Row className="g-3 ">
                <Col xs="6" md="3" xl="6">
                  <Link onClick={handleAddSchedule}>
                    <div className={"task-category p-3 bg-success"}>
                      <h3 className="category-title mb-1">Add Schedule</h3>
                    </div>
                  </Link>
                </Col>
                <Col xs="6" md="3" xl="6">
                  <Link onClick= {handleCreateMeeting}>
                    <div className={"task-category p-3 bg-secondary"}>
                      <h3 className="category-title mb-1">Create A Meeting</h3>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Row>
          </Col>

          <Col xl="8"></Col>
        </Row>

        {modalState && selectedBooking && (
          <BookingModalView
            booking={selectedBooking}
            modalInitialState={modalState}
            handleModalClose={handleClose}
            refreshAction={refreshBookings}
          />
        )}
        {scheduleModalState && (
          <ScheduleModal
            modalInitialState={scheduleModalState}
            handleModalClose={handleClose}
          />
        )}

        {creatingMeetingModalState && (
          <CreateMeetingModal
            modalInitialState={creatingMeetingModalState}
            handleModalClose={handleClose}
          
          />
        )}
      </div>
    </React.Fragment>
  );
}
