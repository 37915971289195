
import React from "react";
import SigninComponent from "./authorization/SigninComponent";

export default function Signin() {
 


  return (
    <div className="page-sign">
     <SigninComponent />
    </div>
  )
}