import axios from "axios";
import jwtDecode from "jwt-decode";

const GET_STATS = process.env.REACT_APP_BACKEND_URL + "stats/consultant";
const CANCEL_SCHEDULE = process.env.REACT_APP_BACKEND_URL + "telemedicine/schedule/cancel/";
const ADD_SCHEDULE = process.env.REACT_APP_BACKEND_URL + "telemedicine/schedule/create";
const GET_SCHEDULE = process.env.REACT_APP_BACKEND_URL + "telemedicine/schedule/consultant";


class consultantService {
  // Check if the token is expired
  isTokenExpired(token) {
    if (token === null) {
      return true; 
  }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;


  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

  getStats(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_STATS , {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  cancelSchedule(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(CANCEL_SCHEDULE + id, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  createSchedule(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(ADD_SCHEDULE, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getSchedulesForConsultant(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_SCHEDULE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
}

export const ConsultantService = new consultantService();
