import React from 'react';
import { Placeholder, Card, Table, Button } from 'react-bootstrap';

const PlaceholderComponent = ({ type }) => {
  const renderCardPlaceholder = () => (
    <Card>
      <Card.Body>
        <Card.Title>
          <Placeholder xs="6" animation="glow" />
        </Card.Title>
        <div>
          <Placeholder as="p" animation="glow">
            <Placeholder xs="7" />
            <Placeholder xs="4" />
            <Placeholder xs="6" />
            <Placeholder xs="8" />
          </Placeholder>
        </div>
        <Placeholder.Button variant="primary" xs="6" />
      </Card.Body>
    </Card>
  );

  const renderTablePlaceholder = () => (
    <Table>
      <thead>
      <tr>
      <th>
      <Placeholder as="h2" animation="glow">
        <Placeholder xs="6" />
      </Placeholder>
          </th>
          <th>
          <Placeholder as="h2" animation="glow">
        <Placeholder xs="6" />
      </Placeholder>
          </th>
    </tr>
      </thead>
      <tbody>
        {Array.from({ length: 3 }).map((_, index) => (
          <tr key={index}>
          <Placeholder as="td" animation="glow">
        <Placeholder xs="6" />
      </Placeholder>
      <Placeholder as="td" animation="glow">
        <Placeholder xs="6" />
      </Placeholder>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const renderTextPlaceholder = () => (
    <Placeholder as="p" animation="glow">
      <Placeholder xs="12" />
      <Placeholder xs="8" />
      <Placeholder xs="10" />
      <Placeholder xs="9" />
    </Placeholder>
  );

  const renderPlaceholder = () => {
    switch (type) {
      case 'card':
        return renderCardPlaceholder();
      case 'table':
        return renderTablePlaceholder();
      case 'text':
        return renderTextPlaceholder();
      default:
        return <p>Invalid placeholder type</p>;
    }
  };

  return <>{renderPlaceholder()}</>;
};

export default PlaceholderComponent;
