import React, { useEffect } from 'react';
import './ZoomVideoUi.css';
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';

function ZoomVideoUi(props) {

    const sessionContainerRef = React.useRef(null);

    const config = {
        videoSDKJWT: props.jwt,
        sessionName: props.sessionName,
        userName: props.userName,
        features: ['video', 'audio', 'settings', 'users', 'chat', 'share']
    };

    const joinSession = () => {
        if (sessionContainerRef.current) {
            // Check and request media permissions
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(stream => {
                    // Access granted, join session
                    uitoolkit.joinSession(sessionContainerRef.current, config);
                    uitoolkit.onSessionClosed(sessionClosed);
                })
                .catch(error => {
                    // Handle errors (e.g., permission denied)
                    console.error('Permission denied or error accessing media devices:', error);
                    alert('Please allow camera and microphone access to join the session.');
                });
        }
    };

    const sessionClosed = () => {
        console.log('Session closed');
        if (sessionContainerRef.current) {
            uitoolkit.closeSession(sessionContainerRef.current);
        }
        document.getElementById('join-flow').style.display = 'block';
    };

    useEffect(() => {
        // Example: ensure video elements play inline on iOS
        const videoElements = document.querySelectorAll('video');
        videoElements.forEach(video => {
            video.setAttribute('playsInline', true);
        });
    }, []);

    return (
        <div className="App">
            <main>
                <div id="join-flow">
                    <button onClick={joinSession}>Join Session</button>
                </div>
                <div id='sessionContainer' ref={sessionContainerRef}></div>
            </main>
        </div>
    );
}

export default ZoomVideoUi;
