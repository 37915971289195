import React from "react";
import { Table, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import favicon from "../assets/img/favicon.png";
import Avatar from "../components/Avatar";

const BookingTable = ({ bookings, handleBookingView, role }) => {
  return (
    <Col xs="12">
      <Table className="table-agent mb-0" responsive>
        <tbody>
          {bookings.map((item, index) => (
            <tr onClick={() => handleBookingView(item)} key={index}>
              <td>
                <div className="d-flex align-items-center gap-2">
                  <Avatar img={favicon} />
                  {role === "ENROLLEE" ? (
                    <div>
                      <h6 className="mb-0">{item.consultantName}</h6>
                      <span className="fs-xs text-secondary">
                        {item.appointmentStatus}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <h6 className="mb-0">{item.enrolleeUserName}</h6>
                      <span className="fs-xs text-secondary">
                        {item.appointmentStatus}
                      </span>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <span className="badge bg-success">
                  {item.appointmentTitle}
                </span>
              </td>
              <td>
                <div className="d-flex justify-content-end">
                  <Link
                    to=""
                    className="link-more"
                    onClick={() => handleBookingView(item)}
                  >
                    <i className="ri-eye-fill"></i>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  );
};

export default BookingTable;
