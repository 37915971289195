import axios from "axios";
import jwtDecode from "jwt-decode";

const GETPROFILE = process.env.REACT_APP_BACKEND_URL + "enrollee/info";
const GETPROFILE_BY_ID = process.env.REACT_APP_BACKEND_URL + "enrollee/profile?enrolleeNumber=";
const GETCORPORATEPROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/info";
const GET_SLOTS= process.env.REACT_APP_BACKEND_URL + "telemedicine/schedule/slots";
const VERIFY_PAYMENT =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/callback/zest/";
const UPDATE_PROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/enrollee/edit/profile";
const UPDATE_CORPORATE_PROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/edit/profile";
const GETCORPORATEPLANS =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/plans";
class enrolleeService {
  // Check if the token is expired
  isTokenExpired(token) {
    if (token === null) {
      return true; 
  }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;


  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

  getProfile(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETPROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  getProfileById(token, id) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETPROFILE_BY_ID+id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  getSlots(token,startDate,endDate) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_SLOTS+"?startDate=" +startDate+"&endDate="+endDate, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getBookings(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETCORPORATEPROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }


  editProfile(token, enrolleeRequest) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(UPDATE_PROFILE, enrolleeRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  editCorporateProfile(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(UPDATE_CORPORATE_PROFILE, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

}

export const EnrolleeService = new enrolleeService();
