import React from "react";
import Forbidden from "../pages/Forbidden";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import RetailRegistrationPage from "../pages/RetailRegistrationPage";
import Authorization from "../pages/authorization/Authorization";

const publicRoutes = [
  { path: "/login", element: <Signin /> },
  { path: "/admin", element: <RetailRegistrationPage /> },
  // { path: "/forgot-password", element: <ForgotPassword /> },
  // { path: "/create-password", element: <CreatePassword /> },
  { path: "pages/lock", element: <LockScreen /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> },
  { path: "pages/error-503", element: <ServiceUnavailable /> },
  { path: "pages/error-505", element: <Forbidden /> },
  { path: "authorize", element: <Authorization />}
];

export default publicRoutes;