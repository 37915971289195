import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AppointmentService } from '../service/AppointmentService';
import Swal from "sweetalert2";

const CancelReasonModal = ({ booking, show, handleClose, handleParentClose, refreshAction, showLoadingModal, hideLoadingModal}) => {
  const [cancelReason, setCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);
  const token = localStorage.getItem("token");
  const handleReasonChange = (e) => {
    const selectedReason = e.target.value;
    setCancelReason(selectedReason);
    if (selectedReason === 'others') {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
      setOtherReason(''); // Reset the other reason if it's not needed
    }
  };

 
  const cancelBooking= () => {
    const reason = cancelReason === 'others' ? otherReason : cancelReason;
    if (!reason) {
      alert('You need to select a reason!');
      return;
    }
    const updatedFormData = {
    
      comment: reason,
  
  };
    showLoadingModal();
    AppointmentService.cancelAppointment(token, booking.id, updatedFormData)
    .then((response) => {
      if (response.status === 200) {



     Swal.fire({
          title: "Success",
          text: "Appointment Cancelled Successfully",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            handleClose();
           handleParentClose();
            refreshAction();
          }
        });

       
      }
    })
    .catch((error) => {
      console.log(error);
      hideLoadingModal();
    });}


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="cancelReasonSelect">
            <Form.Label>Please select a reason for cancellation:</Form.Label>
            <Form.Control as="select" value={cancelReason} onChange={handleReasonChange}>
              <option value="">Select a reason</option>
              <option value="reason1">I'M NOT AVAILABLE</option>
              <option value="reason2"> I'M NOT INTERESTED </option>
              <option value="reason3">PREFER NOT TO SAY</option>
              <option value="others">Others</option>
            </Form.Control>
          </Form.Group>
          {showOtherInput && (
            <Form.Group controlId="otherReasonInput">
              <Form.Label>Other Reason:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={cancelBooking}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelReasonModal;
