
const dashboardAdminMenu = [
  {
    "label": "My Dashboard",
    "link": "/dashboard/consultant",
    "icon": "ri-suitcase-2-line"
  },
  {
    "label": "My Bookings",
    "icon": "ri-account-circle-line",
    "link": "/bookings"
  },

];

const metroLinks = [

  // {
  //   "label": "Health Plans",
  //   "link": "plans/individual",
  //   "icon": "ri-checkbox-multiple-line"
  // },
  {
    "label": "Get our Mobile App",
    "icon": "ri-app-store-fill",
    "link": "/get-app"
  },
  // {
  //   "label": "Contact us",
  //   "link": "/apps/chat",
  //   "icon": "ri-question-answer-line"
  // },
  {
    "label": "Our Blog",
    "link": "/blog",
    "icon": "ri-file-text-line",
    "target": "_blank"
  },
];





const dashboardMenuEnrollee = [
  {
    "label": "My Dashboard",
    "link": "/dashboard/enrollee",
    "icon": "ri-suitcase-2-line"
  },
  {
    "label": "Bookings",
    "icon": "ri-calendar-event-line",
    "link": "/bookings"
  },
  {
    "label": "Book a Consultation",
    "icon": "ri-calendar-line",
    "link": "/slots"
  },
  // {
  //   "label": "My Beneficiary List",
  //   "link": "/pages/people",
  //   "icon": "ri-contacts-book-line"
  // },
  // {
  //   "label": "Report Delay",
  //   "link": "/report-delay",
  //   "icon": "ri-error-warning-line"
  // },

];








export { dashboardAdminMenu,dashboardMenuEnrollee, metroLinks };