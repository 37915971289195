import React, { useState } from "react";
import { Badge, ProgressBar, Card } from "react-bootstrap";
import BookingModalView from "./BookingModalView";
import BookingCard from "../../components/BookingCard";

export default function BookingList(props) {
  const bookingList = props.bookingList;

  const [modalState, setModalState] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
    setModalState(true);
  };

  const handleClose = () => {
    // Implement your edit logic here
    setModalState(false);
    setSelectedBooking(null);
  };

  return (
    <React.Fragment>
      <div>
        {bookingList.map((booking, index) => (
          <BookingCard key={booking.id} booking={booking} handleClick={handleBookingClick} />
    
        ))}
        {bookingList.length === 0 && (
          <Card>
            <Card.Body>
              <div className="text-center">
                <h4 className="mb-3">No Bookings Found</h4>
              </div>
            </Card.Body>
          </Card>
        )}
        {(modalState && selectedBooking)&&(
          <BookingModalView
            booking={selectedBooking}
            modalInitialState={modalState}
            handleModalClose={handleClose}
          />
        )}
      </div>
    </React.Fragment>
  );
}
