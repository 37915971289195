import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import HeaderMobile from "../layouts/HeaderMobile";
import { Modal } from "react-bootstrap";
import SigninComponent from "../pages/authorization/SigninComponent";

export default function SigninModal(props) {

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    setShowModal(false);
    window.location.reload();

    // navigate(destination, { replace: true });
  };

  return (
    <React.Fragment>
     
      <div className="main main-docs">
        <Modal   style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)' // Safari support
            }}show={showModal} centered size="m" >
          <Modal.Body
        
          >
            <SigninComponent successAction={handleClose2} />
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
