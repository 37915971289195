import React, { useState, useEffect } from "react";
import { Modal,Col, Button, Card, Form, OverlayTrigger, Tooltip, Row } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { AppointmentService } from "../../service/AppointmentService";
import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";
import Swal from "sweetalert2";

const SlotDetailsModal = ({
  show,
  onHide,
  selectedEvent,
  editMode,
  setEditMode,
}) => {
  const [formData, setFormData] = useState({
    timeSlotId: selectedEvent ? selectedEvent.extendedProps.slotId : "",
    startDateTime: selectedEvent
      ? new Date(selectedEvent.start).toISOString()
      : "",
    endDateTime: selectedEvent ? new Date(selectedEvent.end).toISOString() : "",
    enrolleeHmoId: "",
    consultantId: selectedEvent ? selectedEvent.extendedProps.consultantId : "",
    meetingType: "",
    complaint: "",
  });



  if (!selectedEvent) return null;
  const meetingOptions = [
    { value: "GOOGLE MEET", label: "GOOGLE MEET" },
    { value: "METRO APP", label: "METRO APP" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAppointmentSubmit = async () => {

    showLoadingModal();

    const updatedFormData = {
        timeSlotId: selectedEvent.extendedProps.slotId,
        consultantId: selectedEvent.extendedProps.consultantId,
        startDateTime: selectedEvent.start,
        endDateTime: selectedEvent.end,
        enrolleeHmoId: formData.enrolleeHmoId,  // Retain other existing formData entries
        meetingType: formData.meetingType,
        complaint: formData.complaint
    };

// validate the form data using sweetalert
   
    if (!updatedFormData.meetingType) {
      Swal.fire({
        icon: "error",
        title: "Meeting Channel Required",
        text: "Please select the preferred channel for the meeting",
      });
      return;
    }
    if (!updatedFormData.complaint) {
    
      Swal.fire({
        icon: "error",
        title: "Complaint Required",
        text: "Please describe the reason for the consultation",
      });
      return;
    }
    


 
    try {
      const response = await AppointmentService.createAppointment(
        localStorage.getItem("token"),
        updatedFormData
      );
    
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Appointment Booked",
          text: "Your booking has been submitted, you will be notified when it is approved.",
        });
      }
     
      setEditMode(false);
      onHide(); // Close the modal after saving

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: error.response.data.message,
      });
      console.error("Error creating appointment:", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Book a Consultation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedEvent && !editMode && (
          <Card className="card-one">
            <Card.Header>{selectedEvent.title}</Card.Header>
            <Card.Body>
              <div>
                <div className="event-detail">
                  <i
                    className="ri-calendar-line calendar-icon"
                    style={{ marginRight: "10px" }}
                  ></i>
                  <span className="calendar-label">Date: </span>
                  <span>
                    {new Date(selectedEvent.start).toLocaleDateString()}
                  </span>
                </div>
                <div className="calendar-event-detail">
                  <i
                    className="ri-time-line calendar-icon"
                    style={{ marginRight: "10px" }}
                  ></i>
                  <span className="calendar-label">Time: </span>
                  <span>
                    {new Date(selectedEvent.start).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    -{" "}
                    {new Date(selectedEvent.end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div className="calendar-event-detail">
                  <i
                    className="ri-map-pin-line calendar-icon"
                    style={{ marginRight: "10px" }}
                  ></i>
                  <span className="calendar-label">Location: </span>
                  <span>Virtual Meeting</span>{" "}
                  {/* You can replace this with actual location if available */}
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
        {selectedEvent && editMode && (
          <Card className="card-one">
            {/* <Card.Header>{selectedEvent.title}</Card.Header> */}
            <Card.Body>
              <Form>  
               <Row >
               <Col>
                <Form.Group controlId="formComplaint">

                <Form.Label>Complaint</Form.Label>
             
                  
                  <Form.Control
                    as="textarea"
                    name="complaint"
                    placeholder="Please describe the reason for the consultation" 
                    value={formData.complaint}
                    onChange={handleChange}
                    rows={3}
                  />
                </Form.Group>
                </Col>
               </Row>
               <Form.Group controlId="formMeetingType">
                  <Form.Label> Select your preferred virtual channel </Form.Label>
            
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={meetingOptions.find(
                      (option) => option.value === formData.meetingType
                    )}
                    isClearable
                    isSearchable
                    name="meetingType"
                    options={meetingOptions}
                    onChange={(selectedOption) =>
                      handleChange({
                        target: {
                          name: "meetingType",
                          value: selectedOption ? selectedOption.value : "",
                        },
                      })
                    }
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={editMode ? "success" : "primary"}
          onClick={
            editMode ? handleAppointmentSubmit : () => setEditMode(!editMode)
          }
        >
          {editMode ? "Submit" : "Book An Appointment"}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SlotDetailsModal;
