import axios from "axios";



const LOGIN_API_BASE_URL_ENROLLEE = process.env.REACT_APP_BACKEND_URL + "auth/enrollee/login";
const LOGIN_API_BASE_URL = process.env.REACT_APP_BACKEND_URL + "auth/login";
const OTP_API_BASE_URL = process.env.REACT_APP_BACKEND_URL + "auth/verify/";

class authService{
    

    loginEnrollee (data) {
        return axios.post(LOGIN_API_BASE_URL_ENROLLEE, data, {
            headers: {
                "Content-Type": "application/json"
            }
        });

    }

    loginConsultant (data) {
        return axios.post(LOGIN_API_BASE_URL, data, {
            headers: {
                "Content-Type": "application/json"
            }
        });

    }

   

    verifyOtp(otp) {
        return axios.post(OTP_API_BASE_URL+otp,{
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    // logout(token) {
    //     return axios({
    //         method: "post",
    //         url: LOGOUT_URL,
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //             "Content-Type": "application/json"
    //         }
    //     });
    // }

    // resetPassword(dto, verificationToken) {
    //     return axios.post(ResetPassword_API_BASE_URL + verificationToken, dto, {});
    //   }

    // isTokenExpired (token) {
    //     const decodedToken = jwtDecode(token);
    //     if (decodedToken.exp) {
    //       const expirationTime = decodedToken.exp * 1000;
    //       const currentTime = new Date().getTime();
    //       return currentTime > expirationTime;
    //     }
    //     return true;
    //   }
}

export const AuthService = new authService();