import React from 'react';
import { Card, Badge, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BookingCard = ({ booking, handleClick }) => (

  
  <Card className="card-task" key={booking.id} onClick={() => handleClick(booking)}>
    <Card.Body className="p-3 pb-1">
      <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
        <Badge bg="secondary">{booking.timeRange}</Badge>
        <Badge bg="success">{booking.bookingDate}</Badge>
      </div>
      <ProgressBar style={{ marginTop: '18px' }} now={booking.progress} className="mb-2" />
      <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
        <span className="card-date">{booking.appointmentStatus}</span>
        <Card.Title as="h6">{booking.meetingType}</Card.Title>
      </div>
      {booking.complaint && <p className="fs-sm">{booking.complaint}</p>}
      <div className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
        <span>{booking.hour}</span>
      </div>
      <ProgressBar now={booking.progress} className="mb-2" />

    </Card.Body>
    <Card.Footer className="p-3 pt-0">
      <p className="fs-xs">
        Consultant: <Link to="">{booking.consultantName}</Link>
      </p>
    </Card.Footer>
  
  </Card>
);

export default BookingCard;
