import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Container, Nav, Tab, Row, Col } from "react-bootstrap";

import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";
import Swal from "sweetalert2";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";

import { AppointmentService } from "../../service/AppointmentService";
import { Link } from "react-router-dom";
import BookingList from "./BookingList";
import WhatsappSupport from "../../components/WhatsappSupport";

export default function EnrolleeBookings() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [upcomingBookings, setUpcomingBookings] = React.useState([]);
  const [previousBookings, setPreviousBookings] = React.useState([]);
  const gridStyles = {
    display: 'grid',
    gap: '10px',
    marginTop: '20px',
    gridTemplateColumns: 'repeat(3, 1fr)', // Default for larger screens
  };

  const mobileGridStyles = {
    ...gridStyles,
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  };
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {


    showLoadingModal();


    let role = localStorage.getItem("role");

   if (role === "ENROLLEE") {
      const token = localStorage.getItem("token");
      AppointmentService.getAllMyBookings(token)
        .then((response) => {
        
          if (response.status === 200) {
            const upcoming = response.data.result.filter(
              (booking) => booking.appointmentStatus !== "COMPLETED" && booking.appointmentStatus !== "MISSED"
            );
            const previous = response.data.result.filter(
              (booking) => booking.appointmentStatus === "COMPLETED"  || booking.appointmentStatus === "MISSED"
            );
      
            setUpcomingBookings(upcoming);
            setPreviousBookings(previous);

           
          }
          hideLoadingModal();
        })
        .catch((error) => {
          hideLoadingModal();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while fetching your bookings",
          });
        });
    }

   else{
      AppointmentService.getAllMyBookingsForConsultant(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          const upcoming = response.data.result.filter(
            (booking) => booking.appointmentStatus !== "COMPLETED" && booking.appointmentStatus !== "MISSED"
          );
          const previous = response.data.result.filter(
            (booking) => booking.appointmentStatus === "COMPLETED"  || booking.appointmentStatus === "MISSED"
          );
          // const repeatedArray = new Array(25).fill(previous[0]);
          setUpcomingBookings(upcoming);
          setPreviousBookings(previous);
        }
        hideLoadingModal();
      })
      .catch((error) => {
        console.log(error);
        hideLoadingModal();
      });
    }

  
  }, []);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 p-xxl-5">
        <Row className="row-cols-auto g-4 g-xxl-5">
          <Col xl>
            <h2 className="main-title fs-4 mb-1">Bookings Centre</h2>
            <p className="text-secondary mb-4">
              View your upcoming and previous bookings.
            </p>

            <Tab.Container defaultActiveKey={"upcomingBookings"} className="mt-5">
              <Nav className="nav-line ">
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="upcomingBookings"
                  >
                    Upcoming Bookings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="previousAppointments"
                  >
                    Previous Appointments
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="upcomingBookings">
                  {upcomingBookings.length > 0 && (
                    <div
             
             style={window.innerWidth <= 768 ? mobileGridStyles : gridStyles}
                    >
                      {upcomingBookings.map((booking, index) => (
                        <div key={index}>
                          <BookingList bookingList={[booking]} />{" "}
                          {/* Assumes BookingList takes an array */}
                        </div>
                      ))}
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="previousAppointments">
                {previousBookings.length > 0 && (
                    <div
             
             style={window.innerWidth <= 768 ? mobileGridStyles : gridStyles}
                    >
                      {previousBookings.map((booking, index) => (
                        <div key={index}>
                          <BookingList bookingList={[booking]} />{" "}
                          {/* Assumes BookingList takes an array */}
                        </div>
                      ))}
                    </div>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>

      </div>
      <WhatsappSupport/>
    </React.Fragment>
  );
}
