import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Row, Button, Card, Modal, Col,Form } from "react-bootstrap";
import { ConsultantService } from "../../service/ConsultantService";
import Sidebar from "../../layouts/Sidebar";
import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";
import Swal from "sweetalert2";
import Select from "react-select";
import { AppointmentService } from "../../service/AppointmentService";

export default function CreateMeetingModal(props) {
  const modalInitialState = props.modalInitialState;
  const booking = props.booking;
  const [modalState, setModalState] = useState(modalInitialState);
  const handleModalClose = props.handleModalClose;
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [enrolleeHmoId, setEnrolleeHmoId] = useState(booking?booking.enrolleeNumber:'');
    const [meetingType, setMeetingType] = useState('');
    const [complaint, setComplaint] = useState(booking?booking.complaint:'');
    const [appointmentType, setAppointmentType] = useState(booking?"RESCHEDULED":'');



 /// options for meeting type
    const meetingOptions = [
        { value: "GOOGLE MEET", label: "GOOGLE MEET" },
        { value: "METRO APP", label: "METRO APP" },
        ];

        //options for appointment type
        const appointmentOptions = [
            { value: "RESCHEDULED", label: "RESCHEDULED" },
            { value: "FOLLOW_UP", label: "FOLLOW UP" },
            { value: "REGULAR", label: "REGULAR" },
        
            ];




  const handleAppointmentSubmit = async () => {



    const updatedFormData = {
    
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        enrolleeHmoId: enrolleeHmoId,  // Retain other existing formData entries
        meetingType: meetingType,
        complaint: complaint,
        appointmentType:  appointmentType
    };

// validate the form data using sweetalert
   
    if (!updatedFormData.meetingType) {
      Swal.fire({
        icon: "error",
        title: "Meeting Channel Required",
        text: "Please select the preferred channel for the meeting",
      });
      return;
    }
    if (!updatedFormData.complaint) {
    
      Swal.fire({
        icon: "error",
        title: "Complaint Required",
        text: "Please describe the reason for the consultation",
      });
      return;
    }
    


   showLoadingModal();
    try {
      const response = await AppointmentService.createAppointmentByConsultant(
        localStorage.getItem("token"),
        updatedFormData
      );

      if (response.status === 200) {
        Swal.fire({
            icon: "success",
            title: "Appointment Created",
            text: "The enrollee has been notified of the appointment",
          });
          handleModalClose();
      }
      // Close the modal after saving

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: error.response.data.message,
      });
      console.error("Error creating appointment:", error);
    }
  };


  return (
    <React.Fragment>
      <Sidebar />
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={modalState} onHide={handleModalClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create Meeting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="card-task">
              <Card.Body className="p-3 pb-1">
              <Form>  
               <Row >
               <Row>
      <Col className="form-group">
          <label htmlFor="startTime">Enter Enrolee ID:</label>
          <input
            type="text"
            className="form-control"
            id="enrollee Id"
            value={enrolleeHmoId}
            onChange={(e) => setEnrolleeHmoId(e.target.value)}
          />
        </Col>
        <Col className="form-group">
          <label htmlFor="endTime">Appointment Type</label>
          <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={appointmentOptions.find(
                      (option) => option.value === appointmentType
                    )}
                    isClearable
                    isSearchable
                    name="appointmentType"
                    options={appointmentOptions}
                    onChange={(selectedOption) =>
                        setAppointmentType(selectedOption ? selectedOption.value : "")
                    }
                  />
        </Col>
      </Row>
               <Col>
               {/* seet mtto 20 */}

                <Form.Group className="mt-2 mb-2" controlId="formComplaint" >

                <Form.Label>Complaint</Form.Label>
             
                  
                  <Form.Control
                    as="textarea"
                    name="complaint"
                    placeholder="Please describe the reason for the consultation" 
                    value={complaint}
                    onChange={(e) => setComplaint(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
                </Col>
               </Row>
               <Form.Group  className=" mb-2" controlId="formMeetingType">
                  <Form.Label> Select your preferred virtual channel </Form.Label>
            
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={meetingOptions.find(
                      (option) => option.value === meetingType
                    )}
                    isClearable
                    isSearchable
                    name="meetingType"
                    options={meetingOptions}
                    onChange={(selectedOption) =>
                        setMeetingType(selectedOption ? selectedOption.value : "")
                    }
                  />
                </Form.Group>
              </Form>
              <form id="wizard1">
 

      <section>

      <Row>
      <Col className="form-group">
          <label htmlFor="startTime">Start Time:</label>
          <input
            type="datetime-local"
            className="form-control"
            id="startTime"
            value={startDateTime}
            onChange={(e) => setStartDateTime(e.target.value)}
          />
        </Col>
        <Col className="form-group">
          <label htmlFor="endTime">End Time:</label>
          <input
            type="datetime-local"
            className="form-control"
            id="endTime"
            value={endDateTime}
            onChange={(e) => setEndDateTime(e.target.value)}
          />
        </Col>
      </Row>
      
      </section>
    </form>

              </Card.Body>
              <Card.Footer className="p-3 pt-0"></Card.Footer>
            </Card>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleAppointmentSubmit}>
             Submit
            </Button>

            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
