/* eslint-disable no-unused-vars */
import React from "react";

// Dashboard
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import EnrolleeDashboard from "../dashboard/EnrolleeDashboard"; 
import ConsultantDashboard from "../dashboard/ConsultantDashboard";
// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";
import IndividualPlans from "../pages/Individual/IndividualPlans";
import EnrolleeProfile from "../pages/EnrolleeProfile";
import ReportDelay from "../components/ReportDelay";
import GetApp from "../components/GetApp";
import GetBlog from "../components/Blog";
import BusinessPlans from "../pages/corporate/BusinessPlans";
import ComparePlans from "../pages/ComparePlans";
import BusinessProfile from "../pages/corporate/BusinessProfile";
import StaffUploadWizard from "../pages/corporate/StaffUploadWizard";

import SigninModal from "../components/SigninModal";
import CorporateCustomers from "../pages/admin/CorporateCustomers";
import RetailCustomers from "../pages/admin/RetailCustomers";
import CustomerCRM from "../pages/admin/CustomerCRM";
import VideoMeeting from "../pages/VideoMeeting";
import Slots from "../pages/bookings/Slots";
import EnrolleeBookings from "../pages/bookings/EnrolleeBookings";
import BookingModalView from "../pages/bookings/BookingModalView";
import MedicloudEnrolleeDashboard from "../dashboard/MedicloudEnrolleeDashboard";

const protectedRoutes = [
  // { path: "dashboard/finance", element: <FinanceMonitoring /> },
  // { path: "dashboard/events", element: <EventManagement /> },
  // { path: "dashboard/sales", element: <SalesMonitoring /> },
  // { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  // { path: "dashboard/crypto", element: <Cryptocurrency /> },
  // { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  // { path: "dashboard/storage", element: <StorageManagement /> },
  { path: "dashboard/enrollee", element: <EnrolleeDashboard /> },
  // { path: "/profile/individual", element: <EnrolleeProfile /> },
  // { path: "/profile/consultant", element: <EnrolleeProfile /> },
  {path:"login/popup", element: <SigninModal/>},
  {path:"report-delay", element: <ReportDelay/>},
  {path:"get-app", element: <GetApp/>},
  {path:"blog", element: <GetBlog/>},
  // {path:"dashboard/admin", element: <CustomerCRM/>},
  { path: "meeting/start", element: <VideoMeeting />},
  { path: "bookings", element: <EnrolleeBookings />},
  { path: "bookings/view", element: <BookingModalView />},
  { path: "slots", element: <Slots />},
  { path: "dashboard/enrollee/medicloud", element: <MedicloudEnrolleeDashboard />},
  { path: "dashboard/consultant", element: <ConsultantDashboard />},
 


]

export default protectedRoutes;