import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
const WhatsappSupport = () => {


  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+2349091546863"
        accountName="Call Centre"
        avatar = "https://res.cloudinary.com/dpjni3o5l/image/upload/v1701253450/favicon_yl50ba.png"  
        statusMessage="We are at your service"
        chatMessage={
          "Kindly provide your enrollee ID along with your enquiry."
        }
        placeholder="Type your message...
"
        allowClickAway={true}
        allowEsc={true}
        styles={{ bottom: '50px', right: '50px' }}
      />
    </div>
  );
};

export default WhatsappSupport;
